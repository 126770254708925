import logo from './../../logo.jpg';
import user_image from './../../user1-128x128.jpg';
import { Link } from 'react-router-dom';
import RoleNav from './RoleNav';

function Aside()
{
    return(
        <aside className="main-sidebar sidebar-dark-dark elevation-4" style={{backgroundColor:"rgba(0,0,0,1)"}} >

        <a  className="brand-link">
        <img src={logo} className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">Northwave Credit</span>
        </a>

        <div className="sidebar">
        
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
        <img src={user_image} className="img-circle elevation-2" alt="User Image" />
        </div>
        <div className="info">
        <a href="#" className="d-block">{localStorage.getItem('auth_name')}</a>
        </div>
        </div>

        </div>

        <nav className="mt-2">
        
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
        <RoleNav page="Dashboard" link="/" icon="nav-icon fa fa-landmark" roles={['Payday HR','Payday Finance']} />

        <li className="nav-item">
        <Link to="/dashboard" className="nav-link" >
        <i className="nav-icon fas fa-home"></i>
        <p>Home</p>
        </Link>
        </li>

        <RoleNav page="Staff" link="/staff" icon="nav-icon fa fa-users" roles={['Payday HR','Payday Finance']} />
        <RoleNav page="Pending List" link="/pending" icon="nav-icon fa fa-file" roles={['Payday HR','Payday Finance']} />
        <RoleNav page="Loans" link="/loans" icon="nav-icon fas fa-layer-group" roles={['Payday HR','Payday Finance']} />

        <li className="nav-item">
        <Link to="/profile" className="nav-link">
        <i className="nav-icon fa fa-user-circle"></i>
        <p>My Profile</p>
        </Link>
        </li>

        

        </ul>

        </nav>

        </aside>
    );
}

export default Aside;