const StageOne=({details,handleDetails})=>{
return(
<div className="row">

<div className="col-md-12">

<form className="form">

<div className="row">

<div className="form-group col-md-3">
<label>First Name <span className="red">*</span></label>
<input type="text" className="form-control" name="first_name" value={details.first_name} onChange={handleDetails} />
<span className="error_holder">{details.error_list.first_name}</span>
</div>

<div className="form-group col-md-3">
<label>Last Name <span className="red">*</span></label>
<input type="text" className="form-control"  name="last_name" value={details.last_name}  onChange={handleDetails} />
<span className="error_holder">{details.error_list.last_name}</span>
</div>

<div className="form-group col-md-3">
<label>Other Name</label>
<input type="text" className="form-control"  name="other_name" value={details.other_name} onChange={handleDetails} />
<span className="error_holder">{details.error_list.other_name}</span>
</div>

<div className="form-group col-md-3">
<label>ID / Passport number <span className="red">*</span></label>
<input type="text" className="form-control"  name="id_number" value={details.id_number} onChange={handleDetails} />
<span className="error_holder">{details.error_list.id_number}</span>
</div>

<div className="form-group col-md-3">
<label>Payroll <span className="red">*</span></label>
<input type="text" className="form-control"  name="payroll" value={details.payroll} onChange={handleDetails}  />
<span className="error_holder">{details.error_list.payroll}</span>
</div>

<div className="form-group col-md-3">
<label>Phone <span className="red">*</span></label>
<input type="text" className="form-control"  name="phone" value={details.phone} onChange={handleDetails} />
<span className="error_holder">{details.error_list.phone}</span>
</div>

<div className="form-group col-md-3">
<label>Email <span className="red">*</span></label>
<input type="text" className="form-control"  name="email" value={details.email} onChange={handleDetails}  />
<span className="error_holder">{details.error_list.email}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Type <span className="red">*</span></label>
<select className="form-control"  name="employment_type" value={details.employment_type} onChange={handleDetails} >
<option value ="">Select employment type</option>
<option value ='Permanent'>Permanent</option>
<option value ='Contract'>Contract</option>
</select>

<span className="error_holder">{details.error_list.employment_type}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Date <span className="red">*</span></label>
<input type="date" className="form-control"  name="employment_date" value={details.employment_date} onChange={handleDetails} />
<span className="error_holder">{details.error_list.employment_date}</span>
</div>



<div className="form-group col-md-3">
<label>Contract Expiry</label>
<input type="date" className="form-control"  name="contract_expriry"value={details.contract_expiry} onChange={handleDetails}  />
<span className="error_holder">{details.error_list.contract_expiry}</span>
</div>

<div className="form-group col-md-3">
<label>Gross Income <span className="red">*</span></label>
<input type="number" className="form-control"  name="gross_income" value={details.gross_income} onChange={handleDetails}  min='0' />
<span className="error_holder">{details.error_list.gross_income}</span>
</div>

<div className="form-group col-md-3">
<label>Net Income <span className="red">*</span></label>
<input type="number" className="form-control"  name="net_income" value={details.net_income} onChange={handleDetails}  min='0'  />
<span className="error_holder">{details.error_list.net_income}</span>
</div>

<div className="form-group col-md-3">
<label>Total Deductions <span className="red">*</span></label>
<input type="number" className="form-control"  name="total_deductions" value={details.total_deductions} onChange={handleDetails}   min='0' />
<span className="error_holder">{details.error_list.total_deductions}</span>
</div>

</div>

</form>

</div>

</div>
)

}
export default StageOne;