import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';
import swal from 'sweetalert';

import EmployeeHolder from '../Elements/Holders/EmployeeHolder';
import EditStaff from '../Elements/Modals/Staff/EditStaff';

import NewLoan from '../Elements/Modals/Loans/NewLoan';

function Employee(){

    const [tab,setTab] = useState('summary')
    const [edit,setEdit] = useState(false)
    const [loans,setLoans] = useState([])
    const effectRun = useRef(false)

    const parameters = useParams()
    const employee = parameters.id

    const [details,setDetails] = useState({})

    const [detail,setDetail] = useState({
    id:'',
    first_name: '',
    last_name: '',
    other_name: '',
    phone: '',
    email: '',
    id_number: '',
    payroll: '',
    gross_income:'',
    net_income:'',
    employment_date:'',
    employment_type:'',
    contract_expiry: '',
    total_deductions:'',
    status:'',
    error_list: [],
    })

    const closeStaff=()=>{
    setEdit(false)
    }

    // Function to fetch staff details
    const fetchDetails=()=>{

    const  data = {
    id: employee,
    }

    axios.post(`api/staff_details`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        const info = res.data.details

        setDetails(info)
        detail.id = info.id
        detail.first_name = info.first_name
        detail.last_name = info.last_name
        detail.other_name = info.other_name
        detail.phone = info.phone
        detail.email = info.email
        detail.id_number = info.id_number
        detail.payroll = info.payroll
        detail.gross_income = info.gross_income
        detail.net_income = info.net_income
        detail.total_deductions = info.total_deduction
        detail.employment_type = info.employment_type
        detail.employment_date = info.employment_date
        detail.contract_expiry = info.contract_expiry
        detail.status = info.status
    }

    })

    }

    const updateStaff=(e)=>{
        e.preventDefault()

        const data = {
        id: detail.id,
        first_name: detail.first_name,
        last_name: detail.last_name,
        other_name: detail.other_name,
        phone: detail.phone,
        email: detail.email,
        id_number: detail.id_number,
        payroll: detail.payroll,
        gross_income: detail.gross_income,
        net_income: detail.net_income,
        employment_date: detail.employment_date,
        employment_type: detail.employment_type,
        contract_expiry: detail.contract_expiry,
        total_deductions: detail.total_deductions,
        status: detail.status
        }
    
    axios.post(`api/update_staff`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        
        fetchDetails()
        swal("Success",res.data.message,'success')
        closeStaff()
    }
    else
    {
        setDetail({...detail, error_list:res.data.validation_errors})
    }

    })

    }

    /********************** Loan Section ********************************/

    const [loanOpen,setLoanOpen] = useState(false)

    const onCloseLoan=()=>{
    setLoanOpen(false)
    }

    /********************** End of loan section ********************************/

    const fetchLoans=()=>{
    
    const data = {
        employee: employee,
    }

    axios.post(`api/employee_loans`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setLoans(res.data.loans)
    }
   

    })

    }

    useEffect(()=>{
   
    if(effectRun.current === false)
    {
        fetchDetails()
        fetchLoans()

        return()=>{
        effectRun.current = true;
        }
    }

    },[])

    return (
        <div className="wrapper">

            <NewLoan loanOpen={loanOpen} onCloseLoan={onCloseLoan} employee={employee} fetchLoans={fetchLoans} />
            <EditStaff edit={edit} closeStaff={closeStaff} detail={detail} setDetail={setDetail} updateStaff={updateStaff} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Staff Details</h5>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>

                <div className='col-md-3'>
                
                <div className='card card-primary card-outline '>

                <div className='card-body box-profile'>
                
                <ul className="list-group list-group-unbordered mb-3">

                <li className="list-group-item" onClick={()=>setTab('summary')} >
                <b>Staff Details</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('loans')} >
                <b>Loans</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('documents')} >
                <b>Documents</b>
                </li>

                </ul>

                </div>

                </div>

                </div>

                <div className='col-md-9'>

                
                <EmployeeHolder tab={tab} details={details} detail={detail} setEdit={setEdit} setLoanOpen={setLoanOpen} loans={loans} />
                

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Employee;