import {Link, NavLink, useNavigate} from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';



function Nav()
{

  const navigate = useNavigate();
  const [notifications,setNotifications] = useState([])
  const [notificationsCount,setCount] = useState([])
  const effectRun = useRef(false)

  const fetchNotifications=()=>{
  
  axios.post(`api/payday_notifications`).then((res)=>{
  
  if(res.data.status === 200)
  {
    setNotifications(res.data.notifications)
  }
  
  }).catch(error=>{
    if(error == "AxiosError: Request failed with status code 401")
    {
      localStorage.clear()
      navigate('/login');
    }
})

  }

	const logoutSubmit = (e) =>{
		axios.post(`api/logout`).then(res => {

			if(res.data.status === 200)
			{
				localStorage.clear()
				swal("Success",res.data.message,"success");
				navigate('/login');
			}

		}).catch(error=>{
      if(error == "AxiosError: Request failed with status code 401")
      {
        localStorage.clear()
        navigate('/login');
      }
  })
	}

   

    const notificationLink=(e,link,id)=>{
    e.preventDefault()
    const data = {
    id:id,
    }

    axios.post(`api/mark_payday_notification`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
      fetchNotifications()
      navigate(link)
    }

    })
    
    }

    useEffect(()=>{
        
    if(effectRun.current === false)
    {
      fetchNotifications()

      return()=>{
      effectRun.current = true
      }

    }

    },[])

    return(
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-dark">
      <ul className="navbar-nav">
      <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
      </li>
      </ul>
  
      <ul className="navbar-nav ml-auto">

      <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
      <i className="far fa-bell"></i>
      <span className="badge badge-warning navbar-badge"> {notifications.length} </span>
      </a>

      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <span className="dropdown-header">Notifications</span>

      {notifications.map((item,index)=>{
        return(
      <span key={index}>
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item" style={{fontSize:'12px',height:'50px'}} onClick={(e)=>notificationLink(e,item.link,item.id)} >
      <i className="fas fa-envelope mr-2"></i> {item.message}
      <span className="float-left text-muted text-sm" style={{fontSize:'12px'}} >{item.time}</span>
      </a>
      </span>
        )
      })}

      
                   
      <div className="dropdown-divider"></div>
      <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
      </div>

      </li>
      <li className="nav-item">
      <a className="nav-link" style={{color:'red'}} onClick={()=>logoutSubmit()}>
      <i className="fa fa-power-off"  ></i></a>
      </li>

      </ul>
      </nav>
      
      )

    

    
}

export default Nav;