import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Outlet, Navigate } from 'react-router-dom';

function DynamicRoutes(){
    
   if(localStorage.getItem('auth_role') == "Payday Employee")
   {
        return(<Navigate to="/dashboard" />)
   }
   else
   {
        return(<Outlet />)
   }

}

export default DynamicRoutes;