import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ContentHeader from '../Components/nav/ContentHeader';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';
import swal from 'sweetalert';

import NewStaff from '../Elements/Modals/Staff/NewStaff';


function Pending(){
const effectRun = useRef(false)
const [open,setOpen] = useState(false)
const [stage,setStage] = useState(1)
const [employees,setEmployees] = useState([])

const [details,setDetails] = useState({
    id:'na',
    first_name: '',
    last_name: '',
    other_name: '',
    id_number:'',
    payroll: '',
    phone: '',
    email: '',
    employment_type: '',
    employment_date: '',
    contract_expiry: '',
    gross_income:'',
    net_income:'',
    total_deductions:'',
    role:8,
    error_list: [],
    })

    const clearDetails=()=>{
    
    setDetails({
        id:'na',
        first_name: '',
        last_name: '',
        other_name: '',
        id_number:'',
        payroll: '',
        phone: '',
        email: '',
        employment_type: '',
        employment_date: '',
        contract_expiry: '',
        gross_income:'',
        net_income:'',
        total_deductions:'',
        role:8,
        error_list: [],
        })

    }

    const saveStaff=()=>{

    const data = {
    id: details.id,
    id_number: details.id_number,
    payroll: details.payroll,
    first_name: details.first_name,
    last_name: details.last_name,
    other_name: details.other_name,
    phone: details.phone,
    email: details.email,
    employment_type: details.employment_type,
    employment_date: details.employment_date,
    contract_expiry: details.contract_expiry,
    gross_income: details.gross_income,
    net_income: details.net_income,
    total_deductions: details.total_deductions,
    role: details.role,
    organization: localStorage.getItem('auth_organization')
    }

    axios.post(`api/create_staff`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        setOpen(false)
        details.id = res.data.entry_id
        setDetails({...details, error_list:[]})
        swal("Success",res.data.message,"success")
        clearDetails()
        //setStage(2)
    }else if(res.data.status === 401)
    {

    }
    else
    {
        setDetails({...details, error_list:res.data.validation_errors})
    }

    })

    }

    const onClose=()=>{
    setStage(1)
    setOpen(false)
    }

    const fetchEmployees=()=>{
    
    axios.post(`api/pending_staff`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setEmployees(res.data.staff)
    }

    })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchEmployees()
    }

    return()=>{
    effectRun.current = true
    }

    },[])

    return (
        <div className="wrapper">

                <NewStaff open={open} onClose={onClose} stage={stage} setStage={setStage} details={details} saveStaff={saveStaff} setDetails={setDetails} />

                <Nav/>
                <Aside/>

                <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5><span className='fa fa-users'></span> Pending List</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                <div className='card-header'><span className='fa fa-users'></span> Pending Approval </div>
                
                <div className='card-body'>

                <table className='table'>
                <thead>
                <tr className='row_small'>
                <th>#</th>
                <th>Date</th>
                <th>Staff</th>
                <th>ID</th>
                <th>Payroll</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Type</th>
                <th><span className='fa fa-eye'></span> More</th>
                </tr>
                </thead>

                <tbody>
                {employees.map((item,index)=>{
                return(
                    <tr className='row_small' key={index}>
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                    <td>{item.id_number}</td>
                    <td>{item.payroll}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.employment_type}</td>
                    <td> <Link to={`/employee/${item.id}`}><span className='fa fa-eye'></span> More</Link> </td>
                    </tr>
                )
                })}
                </tbody>

                </table>

                </div>

                <div className='card-footer'>
                </div>
                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Pending;