const ApproveComponent=({action,takeAction})=>{

    

    if(action === "pending" || action === "defered" )
    {
        return(
            <div className="card card-danger card-outline">
            <div className="card-header">
            <span className="fa fa-user-shield"></span> Action required
            </div>
        
            <div className="card-body">
            <span className="btn btn-success btn-xs" style={{margin:'2px'}} onClick={()=>takeAction('approved')} ><span className="fa fa-check-circle"></span> Approve Loan</span>
            <span className="btn btn-danger btn-xs"  style={{margin:'2px'}} onClick={()=>takeAction('rejected')}  ><span className="fa fa-times-circle"></span> Reject Loan</span>
            <span className="btn btn-dark btn-xs"  style={{margin:'2px'}}  onClick={()=>takeAction('defered')}  ><span className="fa fa-minus-circle"></span> Defer Loan</span>
            </div>
        
            </div>
            )
    }
    else
    {
        return<></>
    }

}

export default ApproveComponent;