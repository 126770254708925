import SummaryHolder from "./SummaryHolder";
import LoansHolder from "./LoansHolder";
import DocumentsHolder from "./DocumentsHolder";

const EmployeeHolder=({tab,details,detail,setEdit,setLoanOpen,loans})=>{

if(tab === "summary")
{
    return(<SummaryHolder details={details} detail={detail} setEdit={setEdit}  />)
}else if(tab === "loans")
{
    return(<LoansHolder setLoanOpen={setLoanOpen} loans={loans} />)
}else if(tab === "documents")
{
    return(<DocumentsHolder />)
}

}
export default EmployeeHolder;