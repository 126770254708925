import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, Link} from 'react-router-dom';
import swal from 'sweetalert';
import logo from './../logo.jpg';

const Register=()=>{

    const navigate = useNavigate();
    const [contact,setContacts] = useState({
        organization:'',
        first_name: '',
        last_name: '',
        other_name: '',
        id_number:'',
        payroll: '',
        phone: '',
        email: '',
        employment_type: '',
        employment_date: '',
        contract_expiry: '',
        gross_income:'',
        net_income:'',
        password:'',
        error_list: [],
    })

    const saveAccount=(e)=>{
    e.preventDefault()

    const data = {
        organization: contact.organization,
        first_name: contact.first_name,
        last_name: contact.last_name,
        other_name: contact.other_name,
        id_number: contact.id_number,
        payroll: contact.payroll,
        phone: contact.phone,
        email: contact.email,
        employment_type: contact.employment_type,
        employment_date: contact.employment_date,
        contract_expiry: contact.contract_expiry,
        gross_income: contact.gross_income,
        net_income: contact.net_income,
        password: contact.password,
    }

    axios.post(`api/self_register`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        navigate('/login')
        swal("Success",res.data.message,"success")
    }
    else if(res.data.status === 401)
    {
        swal("Warning",res.data.message,"warning")
    }else{
        setContacts({...contact,error_list: res.data.validation_errors})
    }

    })

    }

    const handleInput=(e)=>{
    setContacts({...contact, [e.target.name]: e.target.value})
    }

    return(<div className='row'>

    <div className='col-md-12' style={{backgroundColor:'rgb(236, 240, 241)'}} >
    <div className="container" >
    <div className='mb-3 mt-5'>
    <div style={{textAlign:'center'}}>
    <img src={logo} style={{width:'60px',borderRadius:'50%'}} />
    <h5>Northwave Credit</h5>
    </div>

    <div className='card card-primary card-outline'>
    <div className='card-header'>
    <h6><span className='fa fa-user-shield'></span> Register and apply for loans today !</h6>
    </div>

    <div className='card-body'>
    
    <form className='form mt-4' onSubmit={saveAccount} >
    
    <div className='row'>
    
    <div className='form-group col-md-3'>
    <label>Organization Code <span className='red'>*</span></label>
    <input type = "text" name="organization" className='form-control' value = {contact.organization} onChange={handleInput} />
    <span className='error_holder'>{contact.error_list.organization}</span>
    </div>
    
    <div className='form-group col-md-3'>
    <label>First Name  <span className='red'>*</span></label>
    <input type = "text" name="first_name" className='form-control'  value = {contact.first_name} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.first_name}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Other Name</label>
    <input type = "text" name="other_name" className='form-control'  value = {contact.other_name} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.other_name}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Last Name  <span className='red'>*</span></label>
    <input type = "text" name="last_name" className='form-control'  value = {contact.last_name} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.last_name}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>ID Number  <span className='red'>*</span></label>
    <input type = "text" name="id_number" className='form-control'  value = {contact.id_number} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.id_number}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Payroll  <span className='red'>*</span></label>
    <input type = "text" name="payroll" className='form-control'  value = {contact.payroll} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.payroll}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Phone  <span className='red'>*</span></label>
    <input type = "text" name="phone" className='form-control'  value = {contact.phone} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.phone}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Email  <span className='red'>*</span></label>
    <input type = "text" name="email" className='form-control' value = {contact.email} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.email}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Employment Type  <span className='red'>*</span></label>
    <select className='form-control' name="employment_type"  value = {contact.employment_type} onChange={handleInput}  >
    <option value=''>Select employment type</option>
    <option>Permanent</option>
    <option>Contract</option>
    </select>
    <span className='error_holder'>{contact.error_list.employment_type}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Employment Date  <span className='red'>*</span></label>
    <input type = "date" name="employment_date" className='form-control'  value = {contact.employment_date} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.employment_date}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Contract expiring on</label>
    <input type = "date" name="contract_expiry" className='form-control'  value = {contact.contract_expiry} onChange={handleInput}  />
    
    </div>

    <div className='form-group col-md-3'>
    <label>Gross Income  <span className='red'>*</span></label>
    <input type = "number" name="gross_income" className='form-control' min="1"  value = {contact.gross_income} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.gross_income}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Net Income  <span className='red'>*</span></label>
    <input type = "number" name="net_income" className='form-control'  min="1"  value = {contact.net_income} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.net_income}</span>
    </div>

    <div className='form-group col-md-3'>
    <label>Password  <span className='red'>*</span></label>
    <input type = "password" name="password" className='form-control'  value = {contact.password} onChange={handleInput}  />
    <span className='error_holder'>{contact.error_list.password}</span>
    </div>

    <div className='form-group col-md-12'>
    <input type = "submit" className='btn btn-primary btn-sm' value="Register" />
    <Link to='/login' ><span className='btn btn-dark btn-sm float-right'><span className='fa fa-arrow-left'></span>  Back</span></Link>
    </div>

    </div>

    </form>

    </div>
    <div className='card-footer'></div>
    </div>

    </div>
    </div>
    </div>

    </div>)
}

export default Register;