import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';
import swal from 'sweetalert';
import NewLoan from '../Elements/Modals/Loans/NewLoan';

function Dashboard(){

    const employee = localStorage.getItem('auth_id')
    const effectRun = useRef(false)
    const [loanOpen,setLoanOpen] = useState(false)
    const [loans,Setloans] = useState([])

    const [stats,setStat] = useState({
        loan_count:0,
        disbursed:0,
        balance:0,
        })

    const onCloseLoan=()=>{
    setLoanOpen(false)
    }

    const fetchLoans=()=>{
    
    const data = {
    employee: employee
    }

    axios.post(`api/employee_loans`,data).then((res)=>{
        if(res.data.status === 200)
        {
            Setloans(res.data.loans)
            setStat(res.data.stats)
        }
    })

    }

    useEffect(()=>{
    
        if(effectRun.current === false)
        {
            fetchLoans()
        }
    
        effectRun.current = true
    })

    return (
        <div className="wrapper">

            <NewLoan loanOpen={loanOpen} onCloseLoan={onCloseLoan} employee={employee} fetchLoans={fetchLoans} />

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Dashboard</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <span className='fa fa-user-circle'></span> {localStorage.getItem('auth_name')}
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                
                    <div className="col-lg-4 col-sm-12">
                    <div className="small-box bg-success">
                    <div className="inner">
                    <h3>{stats.loan_count}</h3>
                    <p>My Loans</p>
                    </div>
                    <div className="icon">
                    <i className="fa fa-coins"></i>
                    </div>
                    
                    </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                    <div className="small-box bg-success">
                    <div className="inner">
                    <h3>KES {parseInt(stats.disbursed).toLocaleString('en-US')}</h3>
                    <p>Amount Disbursed</p>
                    </div>
                    <div className="icon">
                    <i className="fa fa-chart-line"></i>
                    </div>
                    
                    </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                    <div className="small-box bg-success">
                    <div className="inner">
                    <h3>KES {stats.balance}</h3>
                    <p>Loan Balance</p>
                    </div>
                    <div className="icon">
                    <i className="fa fa-money-bill"></i>
                    </div>
                    
                    </div>
                    </div>


                </div>

                <div className='row'>
                
                <div className='col-md-12'>
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Loans History <span className="btn btn-primary btn-xs float-right" onClick={()=>setLoanOpen(true)} ><span className='fa fa-plus-circle'></span>  Apply Loan</span></h6>
                </div>

                <div className='card-body'>

                <table className='table'>
                    <thead>
                        <tr className='row_small' >
                        <th>#</th>
                        <th>Date</th>
                        <th>Loan #</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th> <span className='btn btn-xs btn-dark' > <span className='fa fa-eye'></span> More </span> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loans.map((item,index)=>{
                        return(
                            <tr  className='row_small' key={index} >
                                <td>{index+1}</td>
                                <td>{item.created_at.substr(0,10)}</td>
                                <td>{item.loan_code}</td>
                                <td>KES {parseInt(item.total_amount).toLocaleString('en-US')}</td>
                                <td>{item.status}</td>
                                <td> <Link to={`/loan/${item.id}`} > <span className='btn btn-xs btn-dark'> <span className='fa fa-eye'></span> More </span> </Link> </td>
                            </tr>
                        )
                        })}
                    </tbody>
                </table>

                </div>

                </div>
                </div>

                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Dashboard;