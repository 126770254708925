const Previous=({stage,handlePrevious})=>{

if(stage > 1)
{
    return(
        <span className="btn btn-dark btn-sm" onClick={()=>handlePrevious()} ><span className="fa fa-arrow-left"></span> Previous </span>
    )
}

}
export default Previous;