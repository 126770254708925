const DocumentsHolder=()=>{

    return(
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6>My Documents</h6>
        </div>

        <div className="card-body">
       
        </div>

        <div className="card-footer">
       
       </div>

        </div>
    )

}

export default DocumentsHolder;