import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';

import ApproveComponent from '../Elements/Cards/ApproveComponent';

function Loan(){
    const loan_id = useParams().id
    const [approvers,setApprovers] = useState([])
    const [action,setAction] = useState({
    id:'',
    status:''
    })

    const [details,setDetails] = useState({
    loan_code: '',
    first_name:'',
    other_name:'',
    last_name:'',
    id_number:'',
    payroll:'',
    created_at:''
    })

    const fetchAction=()=>{

    const data = {
        loan: loan_id,
    }

    axios.post(`api/fetch_action`,data).then((res)=>{
        if(res.data.status === 200)
        {
            if(res.data.action !== null)
            {
                setAction(res.data.action)
            }
            else
            {
                setAction('')
            }
            
        }
    })

    }

    const fetchApprovers=()=>{

        const data = {
        id:loan_id,
        }

        axios.post(`api/loan_approvers`,data).then((res)=>{
            if(res.data.status === 200)
            {
                setApprovers(res.data.approvers)
            }
        })

    }

    const fetchDetails=()=>{
    
    const data = {
    id: loan_id,
    }

    axios.post(`api/payday_loan_details`,data).then((res)=>{
        if(res.data.status === 200)
        {
            setDetails(res.data.details)
        }
    })

    }

    const takeAction=(status)=>{
    
    const data = {
        id: action.id,
        loan: loan_id,
        status: status,
    }

    axios.post(`api/action_loan`,data).then((res)=>{
        if(res.data.status === 200)
        {
            fetchAction()
            fetchApprovers()
            swal("Success",res.data.message,"success")
        }
    })

    }

    useEffect(()=>{
    fetchDetails()
    fetchApprovers()
    fetchAction()
    },[])    

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loan Details</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                <form className="form-inline ml-0 ml-md-3">
                <div className="form-group input-group-sm">
                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search"></i>
                </button>
                </div>
                </div>
                </form>
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                Loan Details : {details.loan_code}
                </div>

                <div className='card-body'>
                    <div className='row'>

                    <div className='col-md-4'>

                    <p className='label-block'>
                    <span className='label-holder'>Staff</span> {details.first_name} {details.other_name} {details.last_name}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>ID Number</span> {details.id_number}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Payroll</span> {details.payroll}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Date Applied</span> {details.created_at.substr(0,10)}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Loan approval</span>{details.approval}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Status</span>{details.status}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Loan Code</span>{details.loan_code}
                    </p>

                    </div>

                    <div className='col-md-4'>

                    <p className='label-block'>
                    <span className='label-holder'>Loan Amount</span>KES {parseInt(details.amount_loanable).toLocaleString('en-US')}
                    </p>
                    
                    <p className='label-block'>
                    <span className='label-holder'>Interest Rate</span> {details.interest_rate} %
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Period</span>{details.period} Months
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Total Repayable</span>KES {parseInt(details.total_amount).toLocaleString('en-US')}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Installment</span>KES {parseInt(details.installment).toLocaleString('en-US')}
                    </p>

                    <p className='label-block'>
                    <span className='label-holder'>Take Home</span>KES {parseInt(details.amount_disbursed).toLocaleString('en-US')}
                    </p>

                    </div>

                    <div className='col-md-4'>

                    <ApproveComponent action={action.status}  takeAction={takeAction} />
                    
                    <div className='card card-primary card-outline'>

                    <div className='card-header'>
                    <span className='fa fa-user-shield'></span> Loan approvers
                    </div>

                    <div className='card-body'>
                        {approvers.map((item,index)=>{
                            return(<p key={index}>
                            <span className='fa fa-user-shield'></span> {item.first_name} {item.last_name}  <span style = {{float:'right',padding:'5px'}} className='badge badge-dark' >{item.status}</span>
                            </p>)
                        })}
                    </div>

                    </div>

                    </div>

                    </div>
                </div>

                </div>

                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6>Repayment schedule</h6>
                </div>
                </div>
                
                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Loan;