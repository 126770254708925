import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, Link, useParams} from 'react-router-dom';
import swal from 'sweetalert';

const ResetCode=()=>{

const navigate = useNavigate();
const code = useParams().id

const [loginInput, setLogin] = useState({
    new_password: '',
    confirm_password:'',
    error_list: [],
});

const handleInput = (e) => {
    e.persist();
    setLogin({...loginInput, [e.target.name]: e.target.value})
}

const resetSubmit = (e) => {
    e.preventDefault();

const data = {
    password: loginInput.new_password,
    password_confirmation: loginInput.confirm_password,
    code: code
}

    axios.post(`api/process_reset`,data).then(res => {

            if(res.data.status === 200)
            {
                                
                swal('Success',res.data.message,"success")
                navigate('/login')

            }
            else if(res.data.status === 401)
            {
                  swal('Warning',res.data.message,"warning")
            }
            else
            {
                  setLogin({...loginInput, error_list: res.data.validation_errors})
            }

      })

}


return(
    <div className="hold-transition login-page">
    <div className="login-box">

        <div className="login-logo">
        <span><b>Northwave</b> Credit</span>
        </div>

        <div className = "card" >
        <div className="card-body login-card-body">

            <p className="login-box-msg">Enter new password</p>    
            <form onSubmit={resetSubmit} >

            <div className="input-group mb-1">
            <input type="password" className="form-control" placeholder="New Password" name = "new_password" onChange={handleInput} value={loginInput.reset_code} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fa fa-lock"></span>
            </div>
            </div>
            </div>
            <div className='error_holder'>{loginInput.error_list.password}</div>

            <div className="input-group mb-1 mt-3">
            <input type="password" className="form-control" placeholder="Confirm password" name = "confirm_password" onChange={handleInput} value={loginInput.reset_code} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fa fa-lock"></span>
            </div>
            </div>
            </div>
            <div className='error_holder'>{loginInput.error_list.password_confirmed}</div>

            <div style={{clear:'both',display:'block'}}>
            <span className="error_holder" >{loginInput.error_list.reset_code}</span>
            </div>

            <div className="row mt-4" >
                <div className ="col-md-12" >
                <button type="submit" className="btn btn-primary btn-block" >Update Password</button>
                </div>
            </div>

            </form>

            <p className="mb-3 mt-4">
            <span className='float-left' ><Link to='/login' ><span className='fa fa-arrow-left'></span> Back to Login</Link></span>
            </p>

           

        </div>
        </div>
    </div>
    </div>
)

}

export default ResetCode;