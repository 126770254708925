
const SummaryHolder=({details,detail,setEdit})=>{

return(
   <div className="card card-primary card-outline">

    <div className="card-header">
    <span className="fa fa-clipboard"></span> Staff Details
    <span className="btn btn-primary btn-xs" style={{float:'right'}} onClick={()=>setEdit(true)} >
   <span className="fa fa-edit"></span> Edit Staff
    </span>
    </div>

    <div className="card-body">

   <div className="row">
   
   <div className="col-md-6">

   <p className="label-block">
      <span className="label-holder">First Name</span>{details.first_name}
   </p>

   <p className="label-block">
      <span className="label-holder">Other Name</span>{details.other_name}
   </p>

   <p className="label-block">
      <span className="label-holder">Last Name</span>{details.last_name}
   </p>

   <p className="label-block">
      <span className="label-holder">ID Number</span>{details.id_number}
   </p>

   <p className="label-block">
      <span className="label-holder">Payroll</span>{details.payroll}
   </p>

   <p className="label-block">
      <span className="label-holder">Phone</span>{details.phone}
   </p>

   <p className="label-block">
      <span className="label-holder">Employment Type</span>{details.employment_type}
   </p>

   

   </div>

   <div className="col-md-6">

   <p className="label-block">
      <span className="label-holder">Employment Date</span>{details.employment_date}
   </p>
   
   <p className="label-block">
      <span className="label-holder">Termination Date</span>{details.contract_expiry}
   </p>

   <p className="label-block">
      <span className="label-holder">Email</span>{details.email}
   </p>

   <p className="label-block">
      <span className="label-holder">Status</span>{details.status}
   </p>

   <p className="label-block">
      <span className="label-holder">Gross Income</span>KES {parseInt(details.gross_income).toLocaleString('en-US')}
   </p>

   <p className="label-block">
      <span className="label-holder">Net Income</span>KES {parseInt(details.net_income).toLocaleString('en-US')}
   </p>

   <p className="label-block">
      <span className="label-holder">Total Deductions</span>KES {parseInt(details.total_deduction).toLocaleString('en-US')}
   </p>

   </div>

   </div>
    


    </div>

    <div className="card-footer">
    </div>

   </div>
)

}
export default SummaryHolder;