const StaffHeader=({stage})=>{

if(stage === 1)
{
    return(
    <div className="card-header" >
    <span className="fa fa-clipboard"></span> Employees Details
    </div>
    )
}
else if(stage === 2)
{
    return(
        <div className="card-header">
        <span className="fa fa-file"></span> Employees Documents
        <span className="btn btn-primary btn-xs" style={{float:'right',width:'50px'}}>2 / 2</span>
        </div>
        )
}

}
export default StaffHeader;