const Next=({stage,handleNext,limit})=>{

if(stage < limit)
{
    return (<span className="btn btn-dark btn-sm"  onClick={()=>handleNext()} style={{float:'right'}}>Next <span className="fa fa-arrow-right"></span></span>)
}else if(stage === limit)
{
    return (<span className="btn btn-success btn-sm"  onClick={()=>handleNext()} style={{float:'right'}}><span className="fa fa-check-circle"></span> Complete</span>)
}

}
export default Next;