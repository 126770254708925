import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

import StaffHolder from "./StaffHolder";
import StaffHeader from "./StaffHeader";
import Previous from "../../Buttons/Previous";
import Next from "../../Buttons/Next";

const NewStaff=({open,onClose,stage,setStage,details,saveStaff,setDetails})=>{

    

    const handleDetails=(e)=>{
    setDetails({...details, [e.target.name]: e.target.value})
    }

    const handlePrevious=()=>{
    setStage(stage-1)
    }

    const handleNext=()=>{
    
    if(stage === 1)
    {
        saveStaff()
    }

    }

   

if(!open) return false;

return(
<div className='overlay' onClick={onClose} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-outline">
<StaffHeader stage={stage}/>
<div className="card-body">
<StaffHolder stage={stage} details={details}  handleDetails={ handleDetails}  />
</div>

<div className="card-footer">
<Previous stage={stage} handlePrevious={handlePrevious} />
<Next stage={stage} handleNext={handleNext} limit={1} />
</div>

</div>

</div>
</div>
)

}
export default NewStaff;