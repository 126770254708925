const EditStaff=({edit,closeStaff,detail,setDetail,updateStaff})=>{

    const handleDetails=(e)=>{
    e.persist()
    setDetail({...detail, [e.target.name]: e.target.value})
    }

if(!edit) return false;
return (
<div className='overlay' onClick={closeStaff} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-outline">
<div className="card-header">
<span className="fa fa-edit"></span> Edit Staff
</div>

<div className="card-body">

<form className="form" onSubmit={updateStaff}>

<div className="row">

<div className="form-group col-md-3">
<label>First Name <span className="red">*</span></label>
<input type="text" className="form-control" name="first_name" value={detail.first_name} onChange={handleDetails} />
<span className="error_holder">{detail.error_list.first_name}</span>
</div>

<div className="form-group col-md-3">
<label>Last Name <span className="red">*</span></label>
<input type="text" className="form-control"  name="last_name" value={detail.last_name}  onChange={handleDetails} />
<span className="error_holder">{detail.error_list.last_name}</span>
</div>

<div className="form-group col-md-3">
<label>Other Name</label>
<input type="text" className="form-control"  name="other_name" value={detail.other_name} onChange={handleDetails} />
<span className="error_holder">{detail.error_list.other_name}</span>
</div>

<div className="form-group col-md-3">
<label>ID / Passport number <span className="red">*</span></label>
<input type="text" className="form-control"  name="id_number" value={detail.id_number} onChange={handleDetails} />
<span className="error_holder">{detail.error_list.id_number}</span>
</div>

<div className="form-group col-md-3">
<label>Payroll <span className="red">*</span></label>
<input type="text" className="form-control"  name="payroll" value={detail.payroll} onChange={handleDetails}  />
<span className="error_holder">{detail.error_list.payroll}</span>
</div>

<div className="form-group col-md-3">
<label>Phone <span className="red">*</span></label>
<input type="text" className="form-control"  name="phone" value={detail.phone} onChange={handleDetails} />
<span className="error_holder">{detail.error_list.phone}</span>
</div>

<div className="form-group col-md-3">
<label>Email <span className="red">*</span></label>
<input type="text" className="form-control"  name="email" value={detail.email} onChange={handleDetails}  />
<span className="error_holder">{detail.error_list.email}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Type <span className="red">*</span></label>
<select className="form-control"  name="employment_type" value={detail.employment_type} onChange={handleDetails} >
<option value ="">Select employment type</option>
<option value ='Permanent'>Permanent</option>
<option value ='Contract'>Contract</option>
</select>

<span className="error_holder">{detail.error_list.employment_type}</span>
</div>

<div className="form-group col-md-3">
<label>Employment Date <span className="red">*</span></label>
<input type="date" className="form-control"  name="employment_date" value={detail.employment_date} onChange={handleDetails} />
<span className="error_holder">{detail.error_list.employment_date}</span>
</div>



<div className="form-group col-md-3">
<label>Contract Expiry</label>
<input type="date" className="form-control"  name="contract_expriry"value={detail.contract_expiry} onChange={handleDetails}  />
<span className="error_holder">{detail.error_list.contract_expiry}</span>
</div>

<div className="form-group col-md-3">
<label>Gross Income <span className="red">*</span></label>
<input type="number" className="form-control"  name="gross_income" value={detail.gross_income} onChange={handleDetails}  min='0' />
<span className="error_holder">{detail.error_list.gross_income}</span>
</div>

<div className="form-group col-md-3">
<label>Net Income <span className="red">*</span></label>
<input type="number" className="form-control"  name="net_income" value={detail.net_income} onChange={handleDetails}  min='0'  />
<span className="error_holder">{detail.error_list.net_income}</span>
</div>

<div className="form-group col-md-3">
<label>Total Deductions <span className="red">*</span></label>
<input type="number" className="form-control"  name="total_deductions" value={detail.total_deductions} onChange={handleDetails}   min='0' />
<span className="error_holder">{detail.error_list.total_deductions}</span>
</div>

<div className="form-group col-md-3">
<label>Status <span className="red">*</span></label>
<select className="form-control"  name="status" value={detail.status} onChange={handleDetails}  >
<option value='pending' >pending</option>
<option value='approved'>approved</option>
<option  value='closed' >closed</option>
</select>
<span className="error_holder">{detail.error_list.status}</span>
</div>

</div>

<div className="row">
<div className="col-md-12">
<input type="submit" value="Update Staff" className="btn btn-success btn-sm" />
</div>
</div>

</form>

</div>

<div className="card-footer">

</div>

</div>

</div>
</div>
)
}
export default EditStaff;