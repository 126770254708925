const SettingsHolder=({mpesa,mode,bank,handleMpesa,handleMode,handleBank,updateMode,updateMpesa,updateBank})=>{

    return(
        <div className="card card-primary card-outline">
        <div className="card-header">
        <h6>My Settings</h6>
        </div>

        <div className="card-body">

        <div className="row">
       
        <div className="col-md-12">
        
        <div className="card card-dark card-outline">
        <div className="card-body">

        <div className="col-md-12">
        <form onSubmit={updateMode}>

            <div className="form-group">
            <label>Settlement Mode</label>
            <select className="form-control" name="mode" value={mode} onChange={handleMode} >
            <option>bank</option>
            <option>mpesa</option>
            </select>
            </div>

            <div className="form-group">
            <input type="submit" className="btn btn-sm btn-primary" value="Update settlement mode" />
            </div>

        </form>
        </div>

        </div>
        </div>

        </div>

        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="card card-dark card-outline">
                <div className="card-header">
                <h6>Mpesa Details</h6>
                </div>
                <div className="card-body">
                
                <form onSubmit={updateMpesa}>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Mpesa Number <span className="red" >*</span></label>
                            <input type = "text" name="mpesa" className="form-control"  value={mpesa} onChange={handleMpesa}  />
                        </div>

                        <div className="form-group  col-md-12">
                        <input type="submit" className="btn btn-primary btn-sm" value="Save Mpesa Details"/>
                        </div>

                    </div>
                </form>

                </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="card card-dark card-outline">
                <div className="card-header">
                <h6>Bank Account Details</h6>
                </div>
                <div className="card-body">
                
                <form onSubmit={updateBank}>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label>Bank <span className="red" >*</span></label>
                            <input type = "text" name="bank" className="form-control" value={bank.bank} onChange={handleBank} />
                            <span className="error_holder">{bank.error_list.bank}</span>
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Account Name  <span className="red" >*</span></label>
                            <input type = "text" name="account_name" className="form-control"  value={bank.account_name} onChange={handleBank} />
                            <span className="error_holder">{bank.error_list.account_name}</span>
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Account Number  <span className="red" >*</span></label>
                            <input type = "text" name="account_number" className="form-control"   value={bank.account_number} onChange={handleBank}  />
                            <span className="error_holder">{bank.error_list.account_number}</span>
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Branch  <span className="red" >*</span></label>
                            <input type = "text" name="branch" className="form-control" value={bank.branch} onChange={handleBank} />
                            <span className="error_holder">{bank.error_list.branch}</span>
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Bank Code</label>
                            <input type = "text" name="bank_code" className="form-control"   value={bank.bank_code} onChange={handleBank} />
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Branch Code</label>
                            <input type = "text" name="branch_code" className="form-control"   value={bank.branch_code} onChange={handleBank}  />
                        </div>

                        <div className="form-group  col-md-3">
                            <label>Swift Code</label>
                            <input type = "text" name="swift_code" className="form-control" value={bank.swift_code} onChange={handleBank}  />
                        </div>

                        <div className="form-group  col-md-12">
                        <input type="submit" className="btn btn-primary btn-sm" value="Save Bank Details"/>
                        </div>

                    </div>
                </form>

                </div>
                </div>
            </div>
        </div>

        </div>

        <div className="card-footer">
       
       </div>

        </div>
    )

}

export default SettingsHolder;