import StageOne from "./StageOne";
import StageTwo from "./StageTwo";

const StaffHolder=({stage,details,handleDetails})=>{

if(stage === 1)
{
    return(<StageOne details={details} handleDetails={handleDetails} />)
}
else if(stage === 2)
{
    return(<StageTwo  details={details} />)
}

}
export default StaffHolder;