import NewLoan from "../Modals/Loans/NewLoan";

const LoansHolder=({setLoanOpen,loans})=>{



    return(
        <div className="card card-primary card-outline">

            <div className="card-header">
            Loans
            <span className="btn btn-primary btn-xs" style={{float:'right'}} onClick={()=>setLoanOpen(true)} ><span className="fa fa-plus-circle"></span> Apply Loan</span>
            </div>

            <div className="card-body">

            <table className="table" >
            <thead>
            <tr className="row_small">
            <th>#</th>
            <th>Date</th>
            <th>Loan #</th>
            <th>Amount</th>
            <th>Rate</th>
            <th>Period</th>
            <th>Installment</th>
            <th>Status</th>
            </tr>
            </thead>

            <tbody>

            {loans.map((item,index)=>{
            return(
            <tr key={index} className="row_small" >
            <td>{index+1}</td>
            <td>{item.created_at.substr(0,10)}</td>
            <td>{item.loan_code}</td>
            <td>{parseInt(item.amount_loanable).toLocaleString('en-US')}</td>
            <td>{item.interest_rate} %</td>
            <td>{item.period}</td>
            <td>{parseInt(item.installment).toLocaleString('en-US')}</td>
            <td>{item.status}</td>
            </tr>
            )
            })}

            </tbody>

            </table>

            </div>

            <div className="card-footer"></div>

        </div>
    )

}
export default LoansHolder;