import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, Link} from 'react-router-dom';
import swal from 'sweetalert';

const Login=()=>{

const navigate = useNavigate();

const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    error_list: [],
});

const handleInput = (e) => {
    e.persist();
    setLogin({...loginInput, [e.target.name]: e.target.value})
}

const loginSubmit = (e) => {
    e.preventDefault();

const data = {
    email: loginInput.email,
    password: loginInput.password
}

    axios.post(`api/payday_login`,data).then(res => {

            if(res.data.status === 200)
            {
                localStorage.setItem('auth_token',res.data.token)
                localStorage.setItem('auth_name',res.data.username)
                localStorage.setItem('auth_role',res.data.role)
                localStorage.setItem('auth_id',res.data.id)
                localStorage.setItem('auth_organization',res.data.organization)
                
                swal('Success',res.data.message,"success")
                navigate('/')

            }
            else if(res.data.status === 401)
            {
                  swal('Warning',res.data.message,"warning")
            }
            else
            {
                  setLogin({...loginInput, error_list: res.data.validation_errors})
            }

      })

}


return(
    <div className="hold-transition login-page">
    <div className="login-box">

        <div className="login-logo">
        <a href="../../index2.html"><b>Northwave</b> Credit</a>
        </div>

        <div className = "card" >
        <div className="card-body login-card-body">

            <p className="login-box-msg">Sign in to start your session</p>    
            <form onSubmit={loginSubmit} >

            <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="Email" name = "email" onChange={handleInput} value={loginInput.email} />
            <div className="input-group-append">
            <div className="input-group-text">
            <span className="fas fa-envelope"></span>
            </div>
            </div>
            <span className="error_holder" >{loginInput.error_list.email}</span>
            </div>

            <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Password" name = "password"  onChange={handleInput} value={loginInput.password} />
            <div className="input-group-append" >
            <div className="input-group-text">
            <span className="fas fa-lock"></span>
            </div>
            </div>
            <span className="error_holder" >{loginInput.error_list.password}</span>
            </div>

            <div className="row" >
                <div className ="col-md-12" >
                <button type="submit" className="btn btn-primary btn-block" onClick={loginSubmit} >Sign In</button>
                </div>
            </div>

            </form>

            <p className="mb-3 mt-4">
            <Link to='/reset' ><span className='fa fa-lock'></span> Forgot your password ?</Link>
            <span className='float-right' ><Link to='/register' ><span className='fa fa-user-shield'></span> Register</Link></span>
            </p>

           

        </div>
        </div>
    </div>
    </div>
)

}

export default Login;