import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

const StageTwo=({details})=>{

    const [document,setDocument] = useState({
        doc:'',
        error_list:[],
    })

    const handleDocument=(e)=>{
        setDocument({...document, [e.target.name]: e.target.value})
    }

    const [scan,setFile] = useState('')

    const handleFile =(e)=>{
        e.persist();
        setFile(e.target.files[0])
    }

    const saveDocument=()=>{
    
    const formData = new FormData()

    formData.append('staff', details.id)
    formData.append('doc',document.doc)
    formData.append('scan',scan)
    
    console.log(scan.name)

    }

return(
<div className="row">

<div className="col-md-12">

<div className="card card-primary card-outline">

<div className="card-header">
Documents
</div>

<div className="card-body">
<table className="table">
<thead>
<tr>
<th>#</th>
<th>Document</th>
<th>Scan</th>
<th><span className="fa fa-download"></span>  </th>
<th><span className="fa fa-trash"></span>  </th>
</tr>
</thead>
</table>
</div>

<div className="card-footer">
</div>
</div>

<div className="card card-primary card-outline">
<div className="card-header"><span className="fa fa-upload"></span> Upload Document</div>

<div className="card-body">

<form className="form">
<div className="row">

<div className="form-group col-md-6">
<label>Document</label>
<input type="text" className="form-control" name="doc" value={document.doc} onChange={(e)=>handleDocument(e)} />
<span className="red">{document.error_list.doc}</span>
</div>

<div className="form-group col-md-6">
<label>Scan</label>
<input type="file" name="scan" className="form-control" onChange={handleFile}  />
</div>

<div className="form-group col-md-12">
<span className="btn btn-primary btn-sm" onClick={()=>saveDocument()}>Upload</span>
</div>

</div>
</form>

</div>

<div className="card-footer"></div>
</div>

</div>

</div>
)

}
export default StageTwo;