import ProfileHolder from "./ProfileHolder";
import DocumentsHolder from "./DocumentsHolder";
import SettingsHolder from "./SettingsHolder";

const DetailsHolder=({tab,details,detail,setEdit,mpesa,mode,bank,handleMpesa,handleMode,handleBank,updateMode,updateMpesa,updateBank})=>{

if(tab === "summary")
{
    return(<ProfileHolder details={details} detail={detail} setEdit={setEdit}  />)
}else if(tab === "documents")
{
    return(<DocumentsHolder />)
}else if(tab === "settings")
{
    return(<SettingsHolder mpesa={mpesa} mode={mode} bank={bank} handleMpesa={handleMpesa} handleMode={handleMode} handleBank={handleBank} updateMode={updateMode}  updateMpesa={updateMpesa} updateBank={updateBank} />)
}

}
export default DetailsHolder;