import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';

function Loans(){

const [loans,setLoans] = useState([])


const fetchLoans=()=>{

axios.post(`api/organization_loans`).then((res)=>{

if(res.data.status === 200)
{
    setLoans(res.data.loans)
}

})

}

useEffect(()=>{
    fetchLoans()
},[])

    return (
        <div className="wrapper">
            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>Loans</h5>
                </div>
    
                <div className="col-sm-6">
                <div className="float-sm-right">
                
                </div>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>
                <div className='col-md-12'>
                
                <div className='card card-primary card-outline'>
                <div className='card-header'>
                <h6><i className="nav-icon fas fa-layer-group"></i> Loans</h6>
                </div>

                <div className='card-body'>
                <table className='table'>
                <thead>
                <tr className='row_small'>
                <th>#</th>
                <th>Date</th>
                <th>Loan #</th>
                <th>Staff</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>Period</th>
                <th>Installment</th>
                <th>Status</th>
                </tr>
                </thead>

                <tbody>
                {loans.map((item,index)=>{
                    return(
                    <tr className='row_small' key={index}>
                    <td>{index+1}</td>
                    <td>{item.created_at.substr(0,10)}</td>
                    <td><Link to={`/loan/${item.id}`}>{item.loan_code}</Link></td>
                    <td>{item.first_name} {item.other_name} {item.last_name}</td>
                    <td>KES {parseInt(item.amount_loanable).toLocaleString('en-US')}</td>
                    <td>{item.interest_rate} %</td>
                    <td>{item.period} months</td>
                    <td>KES {parseInt(item.installment).toLocaleString('en-US')}</td>
                    <td>{item.status}</td>
                    </tr>)
                })}
                </tbody>

                <tfoot></tfoot>

                </table>
                </div>

                </div>

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Loans;