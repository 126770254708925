import {BrowserRouter as Router,Routes, Route, Navigate, useNavigate, useParams, Outlet} from 'react-router-dom';
import axios from 'axios';
import React, {lazy, Suspense} from 'react';

import Dashboard from './Pages/Dashboard';
import Index from './Pages/Index';
import Login from './Pages/Login';
import Staff from './Pages/Staff';
import Employee from './Pages/Employee';
import Loans from './Pages/Loans';
import Loan from './Pages/Loan';
import Register from './Pages/Register';
import Pending from './Pages/Pending';
import Profile from './Pages/Profile';
import NotAuthorized from './Pages/NotAuthorized';

import Reset from './Pages/Reset';
import ResetCode from './Pages/ResetCode';

import AdminRoutes from './Components/Authentication/AdminRoutes';
import PrivateRoutes from './Components/Authentication/PrivateRoutes';
import DynamicRoutes from './Components/Authentication/DynamicRoutes';

// const Index = lazy(()=> import('./Pages/Index'))
// const Login = lazy(()=>import('./Pages/Login'))
// const Staff = lazy(()=>import('./Pages/Staff'))

function App() {

  
  //axios.defaults.baseURL = "http://127.0.0.1:8000/";
  axios.defaults.baseURL = "https://laravel.northwaveapp.co.ke/";
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Accept'] = 'application/json';
  //axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
  
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(function(config){
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  return (
  <Router>
  <Routes>

  <Route path = "/login" element={<Login/>} />
  <Route path = "/register" element={<Register/>} />
  <Route path = "/reset" element={<Reset/>} />
  <Route path = "/reset_link/:id" element={<ResetCode/>} />

  <Route element={<PrivateRoutes />}>

  <Route element={<DynamicRoutes></DynamicRoutes>}>
  <Route path = "/" element={<Index/>} />
  </Route>

  <Route element={<AdminRoutes/>} >
  <Route path = "/pending" element={<Pending/>} />
  <Route path = "/staff" element={<Staff/>} />
  <Route path = "/loans" element={<Loans/>} />
  <Route path = "/employee/:id" element={<Employee/>} />
  </Route>

  <Route path = "/unauthorized" element={<NotAuthorized/>} />
  <Route path = "/profile" element={<Profile/>} />
  <Route path = "/dashboard" element={<Dashboard/>} /> 
  <Route path = "/loan/:id" element={<Loan/>} />

  </Route>
  
  </Routes>
  </Router>
  )
}

export default App;
