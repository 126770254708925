import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';

function NotAuthorized(){

    return (
        <div className="wrapper">
        <Nav/>
        <Aside/>

        <div className="content-wrapper">  

        <section class="content-header">
            <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
            <h1>500 Warning !</h1>
            </div>

            <div class="col-sm-6">
        
            </div>
            </div>
            </div>
        </section>

        <section class="content">
            <div class="error-page" style={{paddingTop:'100px'}}>
            <h2 class="headline text-danger"> 500</h2>

            <div class="error-content">
            <h3><i class="fas fa-exclamation-triangle text-danger"></i> Un Authorized</h3>

            <p>
            You do not have permission to access this resource.
            </p>
            </div>        
            </div>
      
        </section>
        </div>

        <ControlSidebar/>
        <Footer/>
        </div>
    )
}

export default NotAuthorized;