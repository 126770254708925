import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const NewLoan=({loanOpen,onCloseLoan,employee,fetchLoans})=>{

const [loan_types,setLoantypes] = useState({})
const effectRun = useRef(false);
const [figures,setFigures] = useState({
amount_loanable:0,
total_interest:'',
total_loan:'',
processing_fee:'',
amount_disbursed:'',
monthly_installment:'',
})

const [info,setInfo] = useState({
    loan_type:'',
    period:'',
    amount:'',
    interest_rate:'',
    error_list: []
})

const handleInfo=(e)=>{
    setInfo({...info, [e.target.name]: e.target.value})
}

const calculateLoan=()=>{

const data = {
period: info.period,
loan_amount: info.amount,
employee: employee,
}

axios.post(`api/payday_calculator`,data).then((res)=>{

if(res.data.status === 200)
{
    setFigures({
        amount_loanable: res.data.info.amount_loanable,
        total_interest: res.data.info.total_interest,
        total_loan: res.data.info.total_loan,
        processing_fee: res.data.info.processing_fee,
        amount_disbursed: res.data.info.amount_disbursed,
        monthly_installment: res.data.info.monthly_installment

    })

}else if(res.data.status === 300)
{
    swal("Warning",res.data.info,'warning')
}
else
{
    setInfo({...info, error_list: res.data.validation_errors})
}

})

}

const createLoan=()=>{

    const data = {
    staff: employee,
    loan_type: info.loan_type,
    period: info.period,
    loan_amount: info.amount,
    interest_rate: info.interest_rate
    }
    
    axios.post(`api/create_payday_loan`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        
        onCloseLoan()

        setInfo({
        period:'',
        amount:'',
        error_list: []
        })
        fetchLoans()
        swal("Success",res.data.message,'success')
    
    }else if(res.data.status === 300)
    {
        swal("Warning",res.data.message,'warning')
    }
    else
    {
        setInfo({...info, error_list: res.data.validation_errors})
    }
    
    })
    
    }

    const fetchLoanTypes=()=>{
    
    axios.post(`api/loan_types`).then((res)=>{
    
    if(res.data.status === 200)
    {
        setLoantypes(res.data.loan_types)
    }

    })

    }

    useEffect(()=>{
    
    if(effectRun.current === false)
    {
        fetchLoanTypes()
        return()=>{
        effectRun.current = true 
        }
    }

    },[])

if(!loanOpen) return false;
return (
<div className='overlay' onClick={onCloseLoan} >
<div className='modalContainer' onClick={(e)=>{e.stopPropagation()}} >

<div className="card card-primary card-outline">

<div className="card-header">
<h6>New Loan</h6>
</div>

<div className="card-body">

<div className="row">

<div className="col-md-5">

<div className="card card-dark">
    <div className="card-header">
    <h6 style={{color:'white'}}><span className="fa fa-clipboard"></span> Loan Details</h6>
    </div>

    <div className="card-body">

    <div className="row">

    <div className="form-group col-md-12 col-sm-12">
    <label>Amount</label>
    <input type="text" className="form-control" name="amount" value={info.amount} onChange={(e)=>handleInfo(e)}/>
    <span className='error_holder'>{info.error_list.loan_amount}</span>
    </div>

    <div className="form-group  col-md-12  col-sm-12">
    <label>Period (months)</label>
    <input type="number" className="form-control" name="period" value={info.period} onChange={(e)=>handleInfo(e)}/>
    <span className='error_holder'>{info.error_list.period}</span>
    </div>

    </div>

    <div className="col-md-12 form-group" >
    <span className="btn btn-dark btn-sm" style={{width:'100%', margin:'2px'}} onClick={calculateLoan} ><span className='fa fa-calculator'></span> Calculate</span>
    <span className="btn btn-primary btn-sm" style={{width:'100%', margin:'2px'}} onClick={createLoan} ><span className='fa fa-paper-plane'></span> Apply Loan</span>
    </div>

    </div>

    <div className="card-footer"></div>
</div>

</div>

<div className="col-md-7">

<div className="card card-dark" style={{height:'375px'}}>
<div className="card-header"><span className="fa fa-calculator"></span> Calculator</div>
<div className="card-body">

<div className='row'>

<div className='col-md-12'>

<p className='label-block'>
<span className='label-holder'>Amount</span>KES {parseInt(figures.amount_loanable).toLocaleString('en-US')}
</p>

<p className='label-block'>
<span className='label-holder'>Total Interest</span>KES {figures.total_interest.toLocaleString('en-US')}
</p>

<p className='label-block'>
<span className='label-holder'>Processing Fee</span>KES {figures.processing_fee.toLocaleString('en-US')}
</p>

<p className='label-block'>
<span className='label-holder'>Total Amount</span>KES {figures.total_loan.toLocaleString('en-US')}
</p>

<p className='label-block'>
<span className='label-holder'>Installment</span>KES {figures.monthly_installment.toLocaleString('en-US')}
</p>



<p className='label-block'>
<span className='label-holder'>Take Home</span><b>KES {parseInt(figures.amount_disbursed).toLocaleString('en-US')}</b>
</p>

</div>

</div>



</div>
<div className="card-footer"></div>
</div>

</div>

</div>



</div>

</div>

</div>
</div>
)

}
export default NewLoan;