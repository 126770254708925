import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import Nav from '../Components/nav/Nav';
import Aside from '../Components/nav/Aside';
import ControlSidebar from '../Components/nav/ControlSidebar';
import Footer from '../Components/nav/Footer';
import swal from 'sweetalert';

import DetailsHolder from '../Elements/Holders/DetailsHolder';
function Profile(){

    const [tab,setTab] = useState('summary')
    const effectRun = useRef(false)
    const employee = localStorage.getItem('auth_id')
    const [details,setDetails] = useState({})

    const [mode,setMode] = useState('')
    const [mpesa,setMpesa] = useState('')
    const [bank,setBank] = useState({
        bank:'',
        account_name:'',
        account_number:'',
        branch:'',
        bank_code:'',
        branch_code:'',
        swift_code:'',
        error_list:[]
    })

    const [detail,setDetail] = useState({
    id:'',
    first_name: '',
    last_name: '',
    other_name: '',
    phone: '',
    email: '',
    id_number: '',
    payroll: '',
    gross_income:'',
    net_income:'',
    employment_date:'',
    employment_type:'',
    contract_expiry: '',
    total_deductions:'',
    status:'',
    error_list: [],
    })

    

    // Function to fetch staff details
    const fetchDetails=()=>{

    const  data = {
    id: employee,
    }

    axios.post(`api/staff_details`,data).then((res)=>{
    
    if(res.data.status === 200)
    {
        const info = res.data.details

        setDetails(info)
        detail.id = info.id
        detail.first_name = info.first_name
        detail.last_name = info.last_name
        detail.other_name = info.other_name
        detail.phone = info.phone
        detail.email = info.email
        detail.id_number = info.id_number
        detail.payroll = info.payroll
        detail.gross_income = info.gross_income
        detail.net_income = info.net_income
        detail.total_deductions = info.total_deduction
        detail.employment_type = info.employment_type
        detail.employment_date = info.employment_date
        detail.contract_expiry = info.contract_expiry
        detail.status = info.status
    }

    })

    }

    

    const fetchProfile=()=>{
    
        const data = {
        id:employee
        }

        axios.post(`api/profile_settings`,data).then((res)=>{
        
        if(res.data.status === 200)
        {
            setMode(res.data.settlement_mode)

            if(res.data.mpesa !== null)
            {
                setMpesa(res.data.mpesa.phone_number)
            }

            if(res.data.bank_details !== null)
            {
                const info = res.data.bank_details

                setBank({ 
                    bank: info.bank,
                    account_name: info.account_name,
                    account_number: info.account_number,
                    branch: info.branch,
                    bank_code: info.bank_code,
                    branch_code: info.branch_code,
                    swift_code: info.swift_code,
                    error_list:[]
                })
            }

            
        }

        })

    }

    const handleMpesa=(e)=>{
        setMpesa(e.target.value)
    }

    const handleMode=(e)=>{
        setMode(e.target.value)
    }

    const handleBank=(e)=>{
        setBank({...bank, [e.target.name]:e.target.value})
    }

    const updateMode=(e)=>{
    e.preventDefault()
    const data = {
        id:employee,
        mode:mode
    }

    axios.post(`api/update_mode`,data).then((res)=>{
        if(res.data.status === 200)
        {
            swal("Success",res.data.message,"success")
        }
    })

    }

    const updateMpesa=(e)=>{
        e.preventDefault()

        const data = {
            id:employee,
            mpesa:mpesa
        }

        axios.post(`api/update_mpesa`,data).then((res)=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success") 
            }
        })
    }

    const updateBank=(e)=>{
        e.preventDefault()

        const data = {
            id:employee,
            bank: bank.bank,
            account_name: bank.account_name,
            account_number: bank.account_number,
            branch: bank.branch,
            bank_code: bank.bank_code,
            branch_code: bank.branch_code,
            swift_code: bank.swift_code,
        }

        axios.post(`api/update_bank`,data).then((res)=>{

            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success")
            }
            else
            {
                setBank({...bank, error_list: res.data.validation_errors})
            }

        })

    }

    useEffect(()=>{
   
    if(effectRun.current === false)
    {
        fetchDetails()
        fetchProfile()

        return()=>{
        effectRun.current = true;
        }
    }

    },[])

    return (
        <div className="wrapper">

            <Nav/>
            <Aside/>

            <div className="content-wrapper">
            
                <div className="content-header">
                <div className="container">
                <div className="row mb-2">
                <div className="col-sm-6">
                <h5>My Profile</h5>
                </div>
    
                </div>
                </div>
                </div>

                <div className='content' >
                <div className='container-fluid' >

                <div className='row'>

                <div className='col-md-3'>
                
                <div className='card card-primary card-outline '>

                <div className='card-body box-profile'>
                
                <ul className="list-group list-group-unbordered mb-3">

                <li className="list-group-item" onClick={()=>setTab('summary')} >
                <b>My Profile</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('documents')} >
                <b>Documents</b>
                </li>

                <li className="list-group-item" onClick={()=>setTab('settings')} >
                <b>Settings</b>
                </li>

                </ul>

                </div>

                </div>

                </div>

                <div className='col-md-9'>

                
                <DetailsHolder tab={tab} details={details} detail={detail} mpesa={mpesa} mode={mode} bank={bank} handleMpesa={handleMpesa} handleMode={handleMode} handleBank={handleBank} updateMode={updateMode} updateMpesa={updateMpesa} updateBank={updateBank} />
                

                </div>
                </div>

                </div>
                </div>

            </div>

            <ControlSidebar/>
            <Footer/>
        </div>
    )
}

export default Profile;